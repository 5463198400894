<template>
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <form @submit.prevent="$emit('submit', { handleSubmit, form })" ref="form" v-loading="submitting"
            class="basic-info-form">
            <div class="mt-3 mb-4 cardStyle px-4 pt-4 pb-0">
                <div class="row mt-2">
                    <div class="col-12">
                        <file-input placeholder="Image" name="profile_image"
                    :rules="{ required: false, extensions:['jpg','jpeg','png'], size: 1024 }" label="Image" ref="image_input"
                    @change="(files) => form.profile_image = files[0]" avatar :uploaded-image="askOurCounselorProfileImage" @deleteImage="(value) => form.delete_profile_image = value" />
                    </div>
                    <div class="col-12 mt-4">
                        <p class="basic-info-form-main-heading">BASIC INFO</p>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <base-input name="email" label="Email*" v-model="form.email" :rules="{ required: true, email: true }"
                            placeholder="Email" maxlength="100">
                        </base-input>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <base-input name="title" label="Title*" v-model="form.title" :rules="{ required: true }"
                            placeholder="Title" maxlength="25">
                        </base-input>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <base-input name="first_name" label="First Name*" v-model="form.first_name" :rules="{ required: true ,alpha:true}"
                            placeholder="First Name" maxlength="25">
                        </base-input>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <base-input name="last_name" label="Last Name*" v-model="form.last_name" :rules="{ required: true,alpha:true }"
                            placeholder="Last Name" maxlength="25">
                        </base-input>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <base-input name="phone" label="Phone" v-model="form.phone" :rules="{ required: false }"
                            placeholder="Phone" v-mask="'###-###-####'" maxlength="25">
                        </base-input>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <base-input name="practice" label="Practice/Hospital Name" v-model="form.practice" :rules="{ required: false }"
                            placeholder="Practice/Hospital Name" maxlength="50">
                        </base-input>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <base-input name="address" label="Street Address*" v-model="form.address" :rules="{ required: true }"
                            placeholder="Street Address" maxlength="100">
                        </base-input>
                    </div>
                    <div class="col-sm-12 col-md-3">
                        <base-input name="pin_code" label="Zip Code*" v-mask="'#####'" v-model="form.pin_code" :rules="{ required: true }"
                            placeholder="Zip Code" maxlength="5">
                        </base-input>
                    </div>
                    <div class="col-sm-12 col-md-3">
                        <base-input name="city" label="City/State*" v-model="form.city" :rules="{ required: true }"
                            placeholder="City/State">
                        </base-input>
                    </div>
                    <div class="col-12 col-md-12">
                        <div class="form-group">
                            <base-editor :rules="{ required:  false }" name="about" label="About" v-model="form.about" :height="200" :maxlength="250"/>
                        </div>
                    </div>
                </div>
            </div>
            <base-button type="dark-blue" size="xl" class="mb-5" native-type="submit">Update</base-button>
        </form>
    </validation-observer>
</template>
  
<script>
import BaseEditor from '@/components/Inputs/BaseEditor.vue';

export default {
    props: {
        askOurCounselor: Object,
        submitting: Boolean
    },
    components: {
        BaseEditor
    },
    data() {

        return {
            form: {
                delete_profile_image: false,
                profile_image: '',
                email: '',
                title: '',
                first_name: '',
                last_name: '',
                phone: '',
                practice: '',
                address: '',
                pin_code: '',
                city: '',
                about: '',

            },
            askOurCounselorProfileImage: '',
        };
    },
    watch: {
        askOurCounselor(newVal) {
            if (Object.keys(newVal).length) {
                this.form.email = newVal.email;
                this.form.title = newVal.title
                this.form.first_name = newVal.first_name
                this.form.last_name = newVal.last_name
                this.form.phone = newVal.phone
                this.form.practice = newVal.practice
                this.form.address = newVal.address
                this.form.pin_code = newVal.pin_code
                this.form.city = newVal.city
                this.form.about = newVal.about
                this.askOurCounselorProfileImage = newVal?.profile_image_url || '';
            }
        }
    },
    methods: {
       
    },
};
</script>
  
<style scoped lang="scss"></style>
  