<template>
    <div class="m-0 px-3 pt-4 pb-0 pr-2">
        <back-button class="ml-4" />
        <div class="ml-2 d-flex justify-content-between">
            <h1>{{ `Ask Our Counselor` }}</h1>
        </div>
        <ask-our-counselor-form ref="form" :ask-our-counselor="askOurCounselor" v-loading="loaders.fetching" :submitting="loaders.submit" @submit="formSubmit" />
    </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import AskOurCounselorForm from "@/views/Components/SuperAdmin/AskOurCounselor/Form";
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';
export default {
    components: {
        BackButton,
        AskOurCounselorForm
    },
    mixins:[generateErrorMessageMixin],
    data() {
        return {
            loaders: {
                fetching: false,
                submit : false
            },
            form: {},
            askOurCounselor: null,
        };
    },
    mounted() {
        this.getAskOurCounselor();
    },
    methods: {
        async getAskOurCounselor() {
            this.loaders.fetching = true;
            try {
                let response = await this.$store.dispatch('_getAskOurCounselor');

                let {
                    data: { data },
                } = response;
                if(data){
                    this.askOurCounselor = data;

                }
            } catch (error) {

            }
            this.loaders.fetching = false;
        },
        formSubmit({handleSubmit,form}){
            this.form = {...form};
            handleSubmit(this.onSubmit)
        },
        async onSubmit() {
            let validation = this.$refs.form.$refs.formValidator;
            this.loaders.submit = true;
            try {
                let fd = new FormData()
                for(let index in this.form){
                    let value = this.form[index];
                    if(['status','delete_profile_image'].includes(index)){
                        fd.append(index,[1,true].includes(value) ? 1 : 0)
                    }else{
                        fd.append(index,value ? value : '');
                    }
                }
                
                let response = await this.$store.dispatch('_updateAskOurCounselor',{fd});

                let { data: { data, message } } = response;

                this.$notify.success({
                    title: 'Ask Our Counselor',
                    dangerouslyUseHTMLString: true,
                    message: message
                });
                this.$router.push({ name: 'admin.ask-our-counselor.show' })
            } catch (error) {
                this.showErrorOnForm(error,'Ask Our Counselor',validation);

            }
            this.loaders.submit = false;
        }
    },
};
</script>
<style scoped lang="scss"></style>
  